<template>
<div class="upload-file">
    <div class="upload-file-text" >
        <button type="button" v-if="!disabled" class="mr-5">
            {{$t('choose_file')}}
            <input type="file" ref="file" @change="fileUpload" :accept="format? format:'.xlsx,.xls,.jpg,.png,.jpeg,.doc, .docx,.ppt, .pptx,.txt,.pdf'" />
        </button>
        <p class="text-base"> {{name}} </p>
    </div>
    <div class="upload-file-list"  >
        <div class="upload-file-list-item" v-for="(item, i) in fileList" :key="i" :class="item.loading?'progress':''">
            <p> <a :href="item.url?item.url:item.file" target="_blank" download> <i class="el-icon-document" style="margin-right:5px;"></i> {{item.name}} </a></p>
            <div class="contols-upload">
                <el-popconfirm @confirm="RemoweFile(i)" confirm-button-text='Ҳа' cancel-button-text='Йўқ' icon="el-icon-info" icon-color="red" title="Сиз ушбу файлни ростдан ўчирмоқчимисиз?">
                    <button slot="reference"  type="button" class="upload-delete" v-if="!disabled" > <i class="el-icon-close"></i> </button>
                </el-popconfirm>
                    <button type="button" :class="{'upload-success':!disabled}"> <i class="el-icon-success"></i> </button>
                    <button type="button" class="upload-progress"> <i class="el-icon-loading"></i> </button>
            </div>
        </div>
    </div>
    <div class="file-Size" v-if="!disabled">
        <span>Битта файл ҳажми 30МБ дан ошмаслиги керак! </span>
        <p class="error-p">{{$t('please_choose_file')}}</p>
    </div>
</div>
</template>

<script>
import axios from 'axios';
export default {
    props: ['value', 'disabled', 'deleteLink', 'name', 'format'],
    data() {
        return {
            fileList: []
        };
    },
    watch: {
        value: {
            handler(after) {
                this.fileList = after;
            },
            immediate: true,
        },
        fileList() {
            this.$emit('input', this.fileList);
        }
    },
    methods: {
        fileUpload(event) {
            var file = event.target.files[0]
            this.$refs.file.value = '';
            if (file.size < 1024 * 1024 * 30) {
                this.fileList.push({
                    name: file.name,
                    url: URL.createObjectURL(file),
                    file: file
                })

            } else {
                this.$swal.fire({
                    icon: 'warning',
                    title: 'Битта файл ҳажми 30МБ дан ошмаслиги керак!',
                    timer: 3000
                });
            }

        },
        RemoweFile(index) {
          console.log('index',index)
            if (this.deleteLink && this.fileList[index].id) {
                axios.delete(`${this.deleteLink}${this.fileList[index].id}`)
                    .then(() => {
                        this.fileList.splice(index, 1);
                    })
            } else {
                this.fileList.splice(index, 1);

            }
        },
    }
};
</script>
